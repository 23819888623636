<template>
  <DefaultTemplate>
    <div class="depositFunds-box">
      <div class="form-left">
        <div class="content">
          <div class="info_box">
            <p>
              {{ langTranslate('deposit.default.channels.', paymentDetails.merchant_variable.description_title) }} -
              Special Page
            </p>
            <DepositInfo :instData="instData" :noteData="noteData" :showNote="showNote"></DepositInfo>
          </div>
          <div class="form_main">
            <p class="title">
              {{
                $t('deposit.cps', {
                  name: paymentDetails.merchant_variable.label
                    ? langTranslate('deposit.default.channels.', paymentDetails.merchant_variable.label)
                    : langTranslate('deposit.default.channels.', paymentDetails.merchant_variable.description_title)
                })
              }}
            </p>
            <el-form label-position="top" :model="cpsForm" ref="cpsForm" status-icon :rules="cpsRules">
              <div class="form-box">
                <ul class="clearfix">
                  <li>
                    <AccountNumber
                      :supportedCurrenciesList="tradeAccountCurrencies"
                      @setCurrency="setCurrency"
                      @setAccountNumber="setAccountNumber"
                    ></AccountNumber>
                  </li>
                  <li>
                    <el-form-item :label="setAmtLabel(accountCurrency, cpsForm.amount)" prop="amount">
                      <numeric-input
                        v-model="cpsForm.amount"
                        :currency="accountCurrency"
                        :precision="2"
                      ></numeric-input>
                    </el-form-item>
                  </li>
                </ul>

                <!-- Attach Variables -->
                <ul class="clearfix" v-if="cpsForm.attachVariables.length > 0">
                  <li v-for="(type, index) in cpsForm.attachVariables" :key="type.key">
                    <!-- Input Field -->
                    <DynamicRestrictInput
                      :label="getFieldLabel(type.field_name)"
                      :name="'attachVariables.' + index + '.value'"
                      :testId="type.key"
                      :restrictionType="type.restriction_type"
                      v-model="cpsForm.attachVariables[index].value"
                      :maxlength="type.field_length"
                      v-if="type.field_type == 'input_field'"
                    ></DynamicRestrictInput>

                    <!-- Dropdown List -->
                    <el-form-item
                      v-if="type.field_type == 'dropdown_list' && type.field_name == getFieldName(type.field_name)"
                      :label="getFieldLabel(type.field_name)"
                      :prop="'attachVariables.' + index + '.value'"
                      :rules="selectRules"
                    >
                      <el-select
                        v-model="cpsForm.attachVariables[index].value"
                        :placeholder="$t('common.field.select')"
                        :data-testid="type.key"
                      >
                        <el-option
                          :label="value.desc"
                          :value="value.value"
                          v-for="(value, name) in type.field_content"
                          :key="name"
                        ></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item
                      v-else-if="type.field_type == 'dropdown_list' && type.field_name != 'depositor'"
                      :label="$t('common.field.bankName')"
                      :prop="'attachVariables.' + index + '.value'"
                      :rules="selectRules"
                    >
                      <el-select
                        v-model="cpsForm.attachVariables[index].value"
                        :placeholder="$t('common.field.select')"
                        :data-testid="type.key"
                      >
                        <el-option
                          :label="value.bank_name"
                          :value="value.bank_code"
                          v-for="(value, name) in type.field_content"
                          :key="name"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </li>
                </ul>

                <ul class="clearfix">
                  <li class="data" v-if="showExchangeRates">
                    <p>
                      {{
                        $t('deposit.default.rate.rate', {
                          oldCurrency: accountCurrency,
                          newCurrency: newCurrencyRate
                        })
                      }}
                      <span>{{ rate }}</span>
                    </p>
                    <p>
                      {{
                        accountCurrency === 'USC' && this.paymentDetails.unique_currency
                          ? 'USD'
                          : paymentDetails.actual_currency_code
                      }}: <span>{{ rateChange }}</span>
                    </p>
                  </li>
                  <li>
                    <el-form-item :label="$t('common.field.imptNotes')">
                      <el-input v-model="cpsForm.notes" data-testid="imptNotes"></el-input>
                    </el-form-item>
                  </li>
                </ul>
              </div>
              <div class="impt_note_box" v-if="isShowImportantNote">
                <p class="mb-3">{{ $t('deposit.nodaPayment.imptNotes') }}</p>
                <p class="mb-3">{{ $t('deposit.nodaPayment.imptNotes1') }}</p>
                <p class="mb-3">{{ $t('deposit.nodaPayment.imptNotes2') }}</p>
                <p class="mb-3">{{ $t('deposit.nodaPayment.imptNotes3') }}</p>
                <el-checkbox
                  v-model="cpsForm.isAgreeImportantNote"
                  name="isAgreeImportantNote"
                  :label="$t('deposit.nodaPayment.acceptNotes')"
                  data-testid="isAgreeImportantNote"
                  class="checkbox"
                ></el-checkbox>
              </div>
              <el-form-item>
                <el-button
                  class="btn-blue"
                  :loading="loading"
                  :disabled="loading || isSubmitBtnDisabled"
                  @click="submitCpsForm()"
                  data-testid="submit"
                >
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form-item>
            </el-form>
            <div v-html="bankHTML"></div>
          </div>
        </div>
      </div>
      <div class="form-right">
        <div
          class="logo"
          :class="paymentLogo(paymentDetails.payment_method, paymentDetails.actual_currency_code)"
        ></div>
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import mixin from '@/mixins';
import cpsMixin from '@/mixins/page/deposit/cpsDeposit';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import DynamicRestrictInput from '@/components/form/DynamicRestrictInput';
import fieldLabelList from '@/constants/depositFieldLabel';
import { countryCodeEnum } from '@/constants/country';

export default {
  name: 'indexCpsUAT',
  components: { NumericInput, AccountNumber, DefaultTemplate, DepositInfo, DynamicRestrictInput },
  mixins: [mixin, cpsMixin],
  data() {
    return {
      rate: 0.0,
      redirectType: 1,
      accountCurrency: '',
      exchangeRates: false,
      showExchangeRates: false,
      showNote: false,
      fieldNameMap: {
        depositor: this.$t('common.field.bankName'),
        Supermarket: this.$t('common.field.supermarket')
      }
    };
  },
  computed: {
    countryCode() {
      return parseInt(this.$store.state.common.countryCode);
    },
    instData() {
      let data = [];

      if (this.walletPaymentTemplate.includes(this.paymentDetails.payment_method)) {
        data = [
          this.$t('deposit.reminder.inst1'),
          this.$t('deposit.reminder.inst2', {
            method: this.langTranslate(
              'deposit.default.channels.',
              this.paymentDetails.merchant_variable?.description_title
            )
          }),
          this.$t('deposit.reminder.inst3', {
            method: this.langTranslate(
              'deposit.default.channels.',
              this.paymentDetails.merchant_variable?.description_title
            )
          })
        ];
      } else if (
        this.bankTransferTemplate2.includes(this.paymentDetails.payment_method) ||
        (this.paymentDetails.payment_method === 'T00600' && this.countryCode === countryCodeEnum.THAILAND)
      ) {
        // T00600 Thailand Instant Bank Transfer 123 (parent=GTH2T00600) support Thailand
        data = [
          this.$t('deposit.reminder.inst1'),
          this.$t('deposit.reminder.inst12'),
          this.$t('deposit.reminder.inst13'),
          this.$t('deposit.reminder.inst6')
        ];
      } else if (this.bankTransferTemplate.includes(this.paymentDetails.payment_method)) {
        data = [
          this.$t('deposit.reminder.inst1'),
          this.$t('deposit.reminder.inst12'),
          this.$t('deposit.reminder.inst13')
        ];
      } else if (this.taiwanbanktransfer.includes(this.paymentDetails.payment_method)) {
        data = [
          this.$t('deposit.reminder.inst1'),
          this.$t('deposit.reminder.inst12'),
          this.$t('deposit.reminder.inst13'),
          this.$t('deposit.reminder.inst29')
        ];
      } else if (this.upiTemplate.includes(this.paymentDetails.payment_method)) {
        data = [
          this.$t('deposit.reminder.inst1'),
          this.$t('deposit.reminder.inst19'),
          this.$t('deposit.reminder.inst10')
        ];
      } else if (this.qrTemplate.includes(this.paymentDetails.payment_method)) {
        data = [
          this.$t('deposit.reminder.inst1'),
          this.$t('deposit.reminder.inst9'),
          this.$t('deposit.reminder.inst10')
        ];
      } else if (this.localDepositorTemplate.includes(this.paymentDetails.payment_method)) {
        data = [
          this.$t('deposit.localdepositor.inst1'),
          this.$t('deposit.reminder.inst1'),
          this.$t('deposit.localdepositor.inst2'),
          this.$t('deposit.localdepositor.inst3')
        ];
      } else if (this.euroNodaPaymaxisLBTTemplate.includes(this.paymentDetails.payment_method)) {
        data = [
          this.$t('deposit.reminder.inst25'),
          this.$t('deposit.reminder.inst26'),
          this.$t('deposit.reminder.inst27'),
          this.$t('deposit.reminder.inst28')
        ];
      } else {
        data = [
          this.$t('deposit.reminder.inst1'),
          this.$t('deposit.reminder.inst7', {
            method: this.cryptoPayment.includes(this.paymentDetails.payment_method)
              ? this.paymentDetails.actual_currency_code
              : this.langTranslate(
                  'deposit.default.channels.',
                  this.paymentDetails.merchant_variable?.description_title
                )
          }),
          this.$t('deposit.reminder.inst8', {
            method: this.cryptoPayment.includes(this.paymentDetails.payment_method)
              ? this.paymentDetails.actual_currency_code
              : this.langTranslate(
                  'deposit.default.channels.',
                  this.paymentDetails.merchant_variable?.description_title
                )
          })
        ];
      }

      return data;
    },
    noteData() {
      let data = [];

      if (this.cryptoPayment.includes(this.paymentDetails.payment_method)) {
        data = [this.$t('deposit.crypto.note')];
      } else if (this.paymentDetails.payment_method === 'T00100_047') {
        data = [this.$t('deposit.bitwallet.note1')];
      } else if (this.paymentDetails.payment_method === 'T00100_051') {
        data = [this.$t('deposit.notes.inst1')];
      } else if (this.paymentDetails.payment_method === 'T00100_057') {
        data = [
          this.$t('deposit.notes.inst4'),
          this.$t('deposit.notes.inst3'),
          this.$t('deposit.skrill.note3'),
          this.$t('deposit.net.note4')
        ];
      } else if (this.paymentDetails.payment_method === 'T00100_058') {
        data = [
          this.$t('deposit.notes.inst2'),
          this.$t('deposit.notes.inst3'),
          this.$t('deposit.net.note3'),
          this.$t('deposit.net.note4')
        ];
      } else if (
        this.paymentDetails.payment_method === 'T00600' &&
        this.paymentDetails.actual_currency_code !== 'KRW'
      ) {
        data = [this.$t('deposit.unionPayCN.note2'), this.$t('deposit.unionPayCN.note3')];
      }

      return data;
    },
    isShowImportantNote() {
      // T00600_140: EU Sepa Noda Paymaxis LBT
      return ['T00600_140'].includes(this.paymentDetails.payment_method);
    },
    isSubmitBtnDisabled() {
      return !this.cpsForm.isAgreeImportantNote && this.isShowImportantNote;
    }
  },
  watch: {
    accountCurrency() {
      this.determineExchangeRate();
      this.updateLimit();
    }
  },
  methods: {
    getFieldLabel(val) {
      const i18nKey = this.getI18nKey(val);

      if (this.$te(i18nKey) || this.$te(i18nKey, 'en_US')) {
        if (i18nKey === 'common.field.paymentAccName' || i18nKey === 'common.field.paymentAccNum')
          return this.$t(i18nKey, { name: val.split(' ')[0] });
        return this.$t(i18nKey);
      }

      return val;
    },
    getI18nKey(val) {
      let label = fieldLabelList[val];
      return label ? label : val;
    },
    getFieldName(fieldName) {
      return this.fieldNameMap[fieldName] ? fieldName : '';
    }
  },
  mounted() {
    // show deposit note for crypto payment
    if (
      this.cryptoPayment.includes(this.paymentDetails.payment_method) ||
      (this.showDepositNotes.includes(this.paymentDetails.payment_method) &&
        !(this.paymentDetails.payment_method === 'T00600' && this.countryCode === countryCodeEnum.THAILAND))
    ) {
      // T00600 Thailand Instant Bank Transfer 123 (parent=GTH2T00600) support Thailand do not show
      this.showNote = true;
    }

    this.cpsForm.attachVariables = this.paymentDetails.attach_variable
      ? this.paymentDetails.attach_variable.filter(f => f.hasOwnProperty('field_type'))
      : '';
    this.cpsForm.mandatory = this.paymentDetails.attach_variable
      ? this.paymentDetails.attach_variable.filter(f => f.length > 0)?.[0]
      : '';
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
